import { ExclamationTriangleIcon } from "@modulz/radix-icons";
import { IconAlertCircle, IconWifiOff } from "@tabler/icons";
import Link from 'next/link';
import { useRouter } from "next/router";
import React, { useEffect, useState, } from 'react';
import { Button, Col, Container, Row } from "reactstrap";
import { showMyCaptcha } from "../../api";
import { FormField } from "../../components/FormField/FormField";
import { SpinningCircles } from "../../components/LoadingRings/LoadingRings";
import FederatedLoginProviders from "../../config/federated-login";
import { isEnterKey } from "../../helpers/keys";
import { getQueryParams, handleNetworkError, } from "../../helpers/utils";
import { createQueryString } from "../../helpers/utils/http";
import { redirectToFederatedLoginProvider, redirectToSourceWithToken, setSession } from "../../helpers/utils/session";
import { isEmpty } from "../../helpers/validation";
import { useNamedState } from "../../hooks/useNamedState";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { useNetwork } from "../../lib/hooks/useOfflineStatus";
import VerifyPhone from "../../views/VerifyPhone";
import { login as makeLoginRequest } from "../../views/login/duck";

let classes = require("classnames");

const Login = ({ app, source, a_redirect_url, i_redirect_url, se }) => {
  let router = useRouter();

  let { isOnline } = useNetwork();
  let [state, setState] = useState({ email: '', password: '' });
  let [formError, setFormError] = useState('');
  let [submitting, setSubmitting] = useState(false);
  let [clientError, setClientError] = useState(false);
  let [showPassword, setShowPassword] = useState(false);

  const [VIEW, setView] = useNamedState("VIEW", "LOGIN_FORM");
  // const [VIEW, setView] = useNamedState("VIEW", "PHONE_VERIFICATION_FORM");
  const [registrationDetails, setRegistrationDetails] = useNamedState("registrationSuccessDetails", null)

  let loginMethod = 'oa';
  const sessionTimeout = se === '1';

  let makeOALoginRequest = async function () {
    try {
      setSubmitting(true);
      setClientError(false);

      let response = await makeLoginRequest({
        email: state.email.trim(),
        password: state.password.trim(),
        source
      }, window.AwsWafIntegration);

      if (response) {
        setSubmitting(false);
        let { token, tokenJSON } = response;

        setSession(token, tokenJSON);

        if (!isEmpty(a_redirect_url)) {
          if (typeof window !== "undefined") {
            a_redirect_url = decodeURIComponent(a_redirect_url);
            a_redirect_url = a_redirect_url.split("?");
            redirectToSourceWithToken(a_redirect_url[0]);
          }
        } else if (!isEmpty(i_redirect_url)) {
          router.push(i_redirect_url);
        } else {
          router.push('/home');
        }
        // TODO check for the existence of a referer link and redirect them to the referer link
      }
    } catch (error) {
      setSubmitting(false);

      handleNetworkError(error, {
        400: (data) => {
          console.log(data)
          let { code, message, codeDeliveryDetails } = data;
          if (message === "User is not confirmed.") {
            if (codeDeliveryDetails && codeDeliveryDetails.deliveryMedium === "email") {
              setFormError(
                <span>
                  <ExclamationTriangleIcon /> This account is pending email verification.<br />
                  <span style={{ lineHeight: 1.2, display: "block" }}>Check your email for a verification email, verify and come back</span>
                </span>
              )
            } else {
              //TODO show the phone verification screen
              setView("PHONE_VERIFICATION_FORM");
              setRegistrationDetails({ ...codeDeliveryDetails, phone: codeDeliveryDetails.destination, });

              // DeliveryMedium: "phone"
              // Destination: "+233******312"
              // RetryAt: "2023-08-30T21:47:28.247Z"
              // RetryMinutes: 5
              // UserSub: "082d9a12-f1a1-4859-a3b1-6245719c8f42"
            }
          } else {
            setFormError(<span><ExclamationTriangleIcon /> {message}</span>)
          }
        },
        "NetworkError": () => {
          setClientError({
            type: "NetworkError",
          });
        }
      }, "LOGIN_FAILED", isOnline);
    }
  }

  let login = function (logInWith = 'oa') {
    setFormError('');
    if (logInWith === 'oa') {
      if (!isEmpty(state.email) && !isEmpty(state.password)) {
        makeOALoginRequest();
      }
    }
  }

  // on mount
  useEffect(() => {
    // router.prefetch('/');

    if (!isEmpty(i_redirect_url)) {
      router.prefetch(i_redirect_url)
    }

    function handleKeyUp(e) {
      if (isEnterKey(e.key)) {
        login();
      }
    }

    if (window.AwsWafCaptcha) {
      showMyCaptcha(AwsWafCaptcha)
    }

    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    }
  }, [router, router.isReady]);

  let handleInputChange = function (name, value,) {
    setState(prevState => ({ ...prevState, [name]: value }));
  }

  const toggleShowPassword = function () {
    setShowPassword(!showPassword);
  }

  let queryString = createQueryString({
    ...(!isEmpty(a_redirect_url) ? { a_redirect_url } : {}),
    ...(!isEmpty(i_redirect_url) ? { i_redirect_url } : {}),
    ...(!isEmpty(app) ? { app } : {}),
    ...(!isEmpty(source) ? { source } : {}),
  })

  return (
    <AuthLayout footer={true} className={""}>
      {
        VIEW === "LOGIN_FORM" ? <Container className="main auth login" style={{ maxWidth: '960px' }}>
          {
            sessionTimeout &&
            <Row
              className="col-md-6 mx-auto justify-content-center slide-down-appear bg-danger flex-md-row m-0 mb-3 overflow-hidden"
              style={{ borderRadius: '6px' }}>
              <Col md={12} className="py-3 px-3">
                <h5 className={'primary-heading font-weight-400 text-white text-primary-font m-0'}
                  style={{ fill: '#fff' }}>
                  <IconAlertCircle size={20} strokeWidth={'3px'} className={"mr-2"} />You were logged out for your safety,
                  please login again!
                </h5>
              </Col>
            </Row>
          }
          {
            clientError &&
            <Row
              className="col-md-6 mx-auto justify-content-center slide-down-appear bg-danger flex-md-row m-0 mb-3 overflow-hidden"
              style={{ borderRadius: '6px' }}>
              <Col md={12} className="py-3 px-3">
                <h6 className={'primary-heading font-weight-400 text-white text-primary-font m-0'}
                  style={{ fill: '#fff' }}>
                  {
                    clientError.type === "NetworkError" ? <><IconWifiOff /> Please check your network connection and try
                      again</> : <><ExclamationTriangleIcon /> {clientError.message || "An error Occurred"} </>
                  }
                </h6>
              </Col>
            </Row>
          }

          <Row className="col-md-6 mx-auto px-0 justify-content-center flex-md-row m-0 bg-white overflow-hidden"
            style={{ borderRadius: '6px' }}>
            {/*<Col md={6} className="bg-gradient-dark-up d-flex p-5 flex-sm-column-reverse">
            <div className="text-right ">
              <h1 className=" text-white">Welcome</h1>
            </div>
          </Col>*/}
            <Col md={12} className="card px-md-3 py-sm-3 ">
              <h4 className="px-3 mt-2 mb-4 text-primary font-weight-600 text-center text-md-left">Login</h4>
              <form onSubmit={(event) => {
                if (!event.currentTarget.checkValidity()) {
                  return;
                }
                event.preventDefault();
                login();
              }}
                className="auth-form">
                {
                  Object.entries(FederatedLoginProviders).map(([provider, { classNames, name, icon, enabled }]) => {
                    const value = loginMethod === name.toLowerCase();

                    return enabled ? (
                      <Row className={"m-0 mb-0"} key={provider}>
                        <Col>
                          <Button
                            tabIndex={4}
                            type={'button'}
                            onClick={() => {
                              setSubmitting(true);
                              redirectToFederatedLoginProvider(provider, a_redirect_url, i_redirect_url)
                            }}
                            className={classes("px-3 py-2 w-100 mb-4", classNames, { disabled: submitting })}>
                            {
                              (!submitting || (submitting && loginMethod !== value)) && (<>{icon}
                                <span>Login with {name}</span></>)
                            }
                            {(submitting && loginMethod === value) && <SpinningCircles color={'gold'} size={20} />}
                          </Button>
                        </Col>
                      </Row>
                    ) : null;
                  })
                }

                <Row className={" my-3 divider"}>
                  <Col className={"divider-bottom mb-3 px-0"} />
                </Row>

                <Row className={"m-0 mb-2"}>
                  <FormField type={'email'} id={'email'} autoFocus tabIndex={1} name={'email'}
                    label={'Email'} inputMode={'email'}
                    onChange={handleInputChange} value={state.email} />
                </Row>
                <Row className={"m-0 mb-3"}>
                  <FormField type={'password'} id={'password'} tabIndex={2} name={'password'}
                    label={'Password'} showPassword={showPassword} onToggleShowPassword={toggleShowPassword}
                    onChange={handleInputChange} value={state.password} />
                </Row>
                <Row className={"m-0 mb-3"}>
                  <div id="waf-captcha" className="w-full"></div>
                </Row>

                <Row className={"m-0 mb-3"}>
                  <Col>
                    {formError !== '' && <p className={'text-danger small mb-1 text-center'}>{formError}</p>}
                    {
                      <button type={"submit"} tabIndex={3}
                        className={classes("gold-gradient btn w-100 py-2 mb-3 lgx3f456hg", {
                          disabled: submitting,
                          'btn-primary': !submitting
                        })}>
                        {(!submitting || (submitting && loginMethod !== 'oa')) && <span>Login</span>}
                        {(submitting && loginMethod === 'oa') && <SpinningCircles color={'gold'} size={20} />}
                      </button>
                    }
                  </Col>
                </Row>
                <p className="small text-center mb-1">
                  <Link href={`/forgot-password`}>
                    <a>Forgot Password?</a>
                  </Link>
                  <span> | </span>
                  <span className={""}>New here? </span>
                  <Link href={`/register?q=${queryString}`}><a className={"font-weight-600"}>Sign Up</a></Link>
                </p>
              </form>
            </Col>
          </Row>
        </Container> : null
      }

      {
        VIEW === "PHONE_VERIFICATION_FORM" && registrationDetails ?
          <VerifyPhone
            phoneNumber={registrationDetails.phone}
            userDetails={registrationDetails}
            message={"Your account has been created. Please verify your phone number to continue."}
          /> : null
      }
    </AuthLayout>
  );
};


export async function getServerSideProps({ query }) {
  let { a_redirect_url, i_redirect_url, q, se } = query;
  let { app, source, } = getQueryParams(q, true); // using this because the url is encoded

  return {
    props: {
      ...(app && { app }),
      ...(source && { source }),
      ...(a_redirect_url ? { a_redirect_url } : { a_redirect_url: '' }),
      ...(i_redirect_url ? { i_redirect_url } : { i_redirect_url: '' }),
      ...(se ? { se } : { se: '' }),
    },
  }
}

export default Login;
