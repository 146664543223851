import React from "react";
import AppleIcon from "../assets/icons/apple.svg";
import GoogleIcon from "../assets/icons/google.svg";

export default {
  GOOGLE : {
    name : 'Google',
    icon : <GoogleIcon className={"mr-2"}/>,
    classNames : ['border', 'google'],
    enabled : true
  },
  APPLE : {
    name : 'Apple',
    icon : <AppleIcon className={"mr-2"}/>,
    classNames : ['apple'],
    enabled : false
  }
}
