import { isEmpty } from "../../helpers/validation";

export const login = async function ({ email, password, source = "" }, _AwsWafIntegration = undefined) {
  try {

    let payload = { username: email, password, ...(!isEmpty(source) && { source }) }

    let rawResponse = await _AwsWafIntegration.fetch(`${process.env.NEXT_PUBLIC_AXIOS_BASE_URL}/login`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
    let response = await rawResponse.json();
    console.log({ response, rawResponse })
    if (response) {
      let token = response;
      let status = rawResponse.status
      let tokenJSON = JSON.stringify(token);

      return { status, token, tokenJSON };
    }

  } catch (error) {

    throw error;
  }
}
